import * as React from 'react'

import Layout from '../components/Layout'
import HomePage from '../components/pages/HomePage'

import Seo from '../components/Seo'

const IndexPage = () => (
  <Layout>
    <Seo
      title="Laska - Restaurant végétalien à Lyon"
      description="Restaurant végétalien situé à deux pas de l’opéra vous propose déjeuner et dîner du lundi au samedi."
      keywords={[
        'végétalien',
        'végétarien',
        'vegan',
        'déjeuner',
        'dîner',
        'restaurant',
        'laska',
        'Lyon',
        'vegetarian',
        'lunch',
        'dinner'
      ]}
    />
    <HomePage />
  </Layout>
)

export default IndexPage

import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { FaStar } from 'react-icons/fa'

import Section from '../../../../Section'

import data from './data'

const Review = ({
  content, name, date, className
}) => (
  <Col xs={12} md={6} lg={3} className={classNames('d-flex flex-column', className)}>
    <div className="pb-2">
      <FaStar />
      <FaStar />
      <FaStar />
      <FaStar />
      <FaStar />
    </div>
    <p className="mt-auto">
      &quot;
      {content}
      &quot;
    </p>
    <div className="text-dark mt-auto">
      <h6>
        {name}
      </h6>
      <p>
        {date}
      </p>
    </div>
  </Col>
)

Review.propTypes = {
  className: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

const Reviews = () => (
  <Section id="reviews" className="bg-brown py-5" fluid>
    <Row>
      <Col xs={12} lg={{ span: 8, offset: 2 }}>
        <h2 className="font-bitter text-center text-dark">What our customers says</h2>
      </Col>
    </Row>
    <Row className="pt-5 pt-lg-5 text-center">
      {data.map((review) => <Review {...review} />)}
    </Row>
  </Section>
)

export default Reviews

import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Image from 'react-bootstrap/Image'

import * as styles from './index.module.scss'

const ImageOverlay = ({ image, text }) => (
  <div className="position-relative">
    <div className="position-relative">
      <Image className="shadow-lg" src={image} loading="lazy" fluid rounded />
      <div className={styles.imageOverlay} />
    </div>
    <h4 className={classNames(styles.overlayText, 'font-bitter text-white')}>
      {text}
    </h4>
  </div>
)

ImageOverlay.propTypes = {
  image: PropTypes.elementType.isRequired,
  text: PropTypes.string.isRequired
}

export default ImageOverlay

import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Section from '../../../../Section'
import ReservationButton from '../../../../ReservationButton'

import StarterImage from './images/starter.jpg'
import LunchImage from './images/lunch.jpg'
import DinnerImage from './images/dinner.jpg'
import DessertImage from './images/dessert.jpg'
import ImageOverlay from './ImageOverlay'

const Menu = () => (
  <Section id="menu" className="bg-light text-dark py-5" fluid>
    <Row className="text-center">
      <Col xs={12} lg={{ span: 6, offset: 3 }}>
        <h5>Cuisine végétale</h5>
        <h2 className="font-bitter text-gold">Menu</h2>
        <p>
          Nous vous proposons de venir déguster notre cuisine pendant
          le déjeuner en semaine à partir de 18&nbsp;€ et samedi midi menu
          unique à 28&nbsp;€ servie en 4 temps. Dîner en 6 services au prix de 49&nbsp;€.
          Nous pratiquons deux services le soir soit à 19h00 soit à
          21h15.
        </p>
        <ReservationButton variant="success" />
      </Col>
    </Row>
    <Row className="text-center pt-5">
      <Col xs={12} md={6} lg={3}>
        <ImageOverlay image={StarterImage} />
      </Col>
      <Col xs={12} md={6} lg={3} className="pt-5 pt-md-0">
        <ImageOverlay image={LunchImage} />
      </Col>
      <Col xs={12} md={6} lg={3} className="pt-5 pt-lg-0">
        <ImageOverlay image={DinnerImage} />
      </Col>
      <Col xs={12} md={6} lg={3} className="pt-5 pt-lg-0">
        <ImageOverlay image={DessertImage} />
      </Col>
    </Row>
  </Section>
)

export default Menu

import React from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Section from '../../../../Section'

const Voucher = () => (
  <Section id="bon-cadeau" className="bg-brown py-5" fluid>
    <Row className="text-center">
      <Col xs={12} lg={{ span: 8, offset: 2 }}>
        <h5 className="text-white">Unique gift</h5>
        <h2 className="font-bitter text-dark">Bon cadeau</h2>
        <p className="text-white">
          Offrez à vos proches un déjeuner ou un dîner au sein de notre
          restaurant.
        </p>
        <Button href="/bon-cadeau" target="_blank" rel="noreferrer noopener">
          Buy bon cadeau
        </Button>
      </Col>
    </Row>
  </Section>
)

export default Voucher

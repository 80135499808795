import React from 'react'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'

import Section from '../../../../Section'

import LaskaInteriorImage from './images/laska-interior.jpg'

const About = () => (
  <Section id="about" className="bg-light py-5" fluid>
    <Row className="text-center">
      <Col xs={{ span: 12, order: 'last' }} lg={{ span: 6, order: 'first' }}>
        <Image
          alt="laska-interior"
          src={LaskaInteriorImage}
          className="img-shadowed pt-5 pt-lg-0"
          loading="lazy"
          fluid
          rounded
        />
      </Col>
      <Col xs={12} lg={6} className="d-flex flex-column justify-content-center">
        <h5 className="text-dark">About</h5>
        <h2 className="font-bitter text-gold">Laska</h2>
        <p className="text-dark">
          Laska signifie amour en slovaque et notre philosophie est de partage
          notre amour de la cuisine et du bon vin ! Nous sommes au croisement de
          la rue Terraille et de la rue Saint Claude, à deux pas de l’opéra.
          Venez vous régaler avec une savoureuse cuisine végétale, 100 % fait
          maison.
        </p>
      </Col>
    </Row>
  </Section>
)

export default About

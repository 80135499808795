import React from 'react'

import Voucher from './Voucher'
import About from './About'
import Menu from './Menu'
import Reviews from './Reviews'

const Sections = () => (
  <>
    <About />
    <Voucher />
    <Menu />
    <Reviews />
  </>
)

export default Sections

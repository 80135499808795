const data = [
  {
    name: 'Cécile',
    date: '10 février 2023',
    content: 'Très belle découverte dans cette petite rue cachée au pied des pentes de la Croix-Rousse...!'
  },
  {
    name: 'Damien',
    date: '8 février 2023',
    content: 'Cadre intimiste, musique éléctro, service conviviale et surtout une cuisine inventive et délicieuse ! je recommande !',
    className: 'pt-5 pt-md-0'
  },
  {

    name: 'Yann',
    date: '3 février 2023',
    content: 'Les choix d\'orientations avec le menu unique et une cuisine complètement vegan sont très intéressants.',
    className: 'pt-5 pt-lg-0'
  },
  {
    name: 'Sandrine',
    date: '31 janvier 2023',
    content: 'Dans un premier temps déçue de ne pas pouvoir choisir ce que j\'allais manger , mais nous avons été bien reçues et renseignées sur les plats. Je recommanderai vivement ce restaurant à mon entourage!',
    className: 'pt-5 pt-lg-0'
  }
]

export default data

import classNames from 'classnames'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import Section from '../../../Section'

import * as styles from './index.module.scss'

import HeroImage from './img/laska-hero.jpg'
import ReservationButton from '../../../ReservationButton'

const HeroSection = () => (
  <Section id="hero" className={classNames('vw-100 text-white bg-brown')} fluid>
    <Row
      className={classNames(styles.heroRow, 'align-items-center py-5 py-lg-0 text-center text-lg-left')}
    >
      <Col xs={12} lg={5}>
        <h1 className="font-bitter text-dark">Laska</h1>
        <p>
          Restaurant avec cuisine 100 % végétale situé à deux pas de l’opéra vous propose déjeuner
          et dîner du mardi au samedi.
        </p>
        <ReservationButton />
      </Col>
      <Col xs={12} lg={{ span: 6, offset: 1 }} className="pt-5 pt-lg-0">
        <Image src={HeroImage} loading="lazy" fluid rounded />
      </Col>
    </Row>
  </Section>
)

export default HeroSection
